import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import BSVForm from '../Components/big/BSVForm';

const url =
  'https://departmentofanimals.us21.list-manage.com/subscribe/post?u=8ed2c39de3b9c27e42325b027&id=afa9b66685';

function BlackSummerVigil() {
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe }) => (
        <BSVForm
          onValidated={(formData) => {
            subscribe(formData);
          }}
        />
      )}
    />
  );
}

export default BlackSummerVigil;
