import React from 'react';
import DarkTheme from '../Components/common/DarkTheme';
import HeaderComp from '../Components/common/Header';
import Container from '../Components/common/Container';
import { Typography } from 'antd';
import Footer from '../Components/common/Footer';
const { Title } = Typography;

function ErrorPage() {
  return (
    <DarkTheme>
      <div>
        <HeaderComp />
        <Container>
          <Title level={4}>Nothing to see here :/</Title>
        </Container>
      </div>
      <Footer />
    </DarkTheme>
  );
}

export default ErrorPage;
