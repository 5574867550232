import React, { useState } from 'react';
import Container from '../common/Container';
import {
  Form,
  Input,
  Button,
  Select,
  Typography,
  Card,
  Row,
  Col,
  Space,
  List,
  Avatar,
  Divider,
  Alert,
} from 'antd';
import { CheckOutlined, FacebookFilled } from '@ant-design/icons';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import stars from '../../assets/stars-crop.png';

import { FacebookShareButton } from 'react-share';
import Seo from '../common/Seo';

const { Title, Text, Paragraph } = Typography;

const layout = {
  wrapperCol: { lg: 14, xl: 10 },
};
const tailLayout = {
  wrapperCol: { lg: 14, xl: 10 },
};

const data = [
  {
    body: (
      <Text className="text readable left">
        Eulogy by Ms Wright delivered by her good friend, Djok voice actor,
        legal academic and legendary environmental leader&nbsp;
        <b>Jacqui Katona</b>, winner of the Goldman Environmental Prize for her
        work on the campaign to stop the Jabiluka uranium mine in Kakadu. Ms
        Katona is a founding member of the&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.msd2020.com/"
        >
          Melbourne School of Discontent
        </a>
        , as well as the narrator of Alexis Wright&rsquo;s&nbsp;
        <em>The Swan Book&nbsp;</em>audiobook among&nbsp;other works.
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        <b>Greg Mullins</b>, Former Commissioner, Fire and Rescue NSW;{' '}
        <a
          href="https://www.climatecouncil.org.au/team/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Climate Councillor
        </a>{' '}
        and founder,{' '}
        <a
          href="https://emergencyleadersforclimateaction.org.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Emergency Leaders for Climate Action
        </a>
        . Greg{' '}
        <a
          href="https://www.theguardian.com/australia-news/2019/apr/09/former-fire-chiefs-warn-australia-unprepared-for-escalating-climate-threat"
          target="_blank"
          rel="noopener noreferrer"
        >
          warned Australia's then–Prime Minister in April 2019
        </a>{' '}
        that a bushfire catastrophe was coming. He pleaded for support and was
        ignored, then risked his life dealing with the ramifications on the
        ground.{' '}
        <i>
          “You couldn’t see very far because of the orange smoke. Everything was
          dark. It was probably 2 o’clock in the afternoon but it was like
          night. Then I saw something moving on the side of the road and I
          walked closer. It was a mob of kangaroos. The speed of that fire with
          its pyroconvective storm driving it in every direction, they had
          nowhere to go. They came out of the forest, on fire, and dropped dead
          on the road. I’ve never seen that. Kangaroos know what to do in a
          fire. They’re fast animals. Climate change, driven by the burning of
          coal, oil, and gas is driving worsening bushfires across Australia,
          and putting our precious, irreplaceable wildlife in danger.”
        </i>
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        Internationally recognised ecologist and{' '}
        <a
          href="https://www.wwf.org.au/about-us/leaders/board-of-directors#gs.m1bq0t"
          target="_blank"
          rel="noopener noreferrer"
        >
          WWF board member
        </a>
        , <b>Professor Christopher Dickman</b> oversaw the work calculating the
        animal deaths from Black Summer. A Fellow of the Australian Academy of
        Science, Professor Dickman already wore the heavy task of being an
        ecologist during the sixth mass extinction, in the country that has{' '}
        <a
          href="https://theconversation.com/gut-wrenching-and-infuriating-why-australia-is-the-world-leader-in-mammal-extinctions-and-what-to-do-about-it-192173"
          target="_blank"
          rel="noopener noreferrer"
        >
          the worst rate of mammalian extinction in the world
        </a>
        . On 8 January 2020 media around the world shared his finding that Black
        Summer fires had{' '}
        <a
          href="https://www.sydney.edu.au/news-opinion/news/2020/01/08/australian-bushfires-more-than-one-billion-animals-impacted.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          killed one billion animals
        </a>
        . Sadly, the fires continued for two more months, and his team’s final
        count was{' '}
        <a
          href="https://www.sydney.edu.au/news-opinion/news/2020/12/07/3-billion-animals-impacted-by-fires-including-60000-koalas-repor.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          three billion
        </a>
        . This does not include invertebrates: it is estimated 240 trillion
        beetles, moths, spiders, yabbies and other invertebrates died in the
        fires.
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        Legendary wildlife veterinarian<b>&nbsp;Dr Howard Ralph OAM</b>
        &nbsp;coming up from Southern Cross Wildlife Hospital in the NSW
        Southern Tablelands. As put by one of his clinic volunteers: "If you
        were an animal suffering or dying, you would want to look up and see
        Howard looking down at you.&rdquo; A qualified human doctor and
        anaesthetist as well as a veterinary surgeon, Dr Ralph's reputation
        stands apart because where other vets would euthanise wildlife, he goes
        to great lengths to provide appropriate veterinary care to each
        individual he meets. From injured red belly black snakes, echidnas and
        wombats who've been hit by cars, kangaroos and ducks with gunshot
        wounds, to performing orthopaedic surgery on a microbat, a sparrow or an
        endangered giant burrowing frog, Dr Ralph is skilled in adapting
        equipment and techniques to save all those who live on these lands, and
        says, &ldquo;It is frustrating that our wild native animals do not get
        the respect they deserve." In a weary interview given during the height
        of Black Summer, he said, "The constant input of pain and suffering is
        tiring emotionally. We deal with that because it needs to be dealt with
        and the good news from my point of view is that we can do something
        about it.&rdquo; Some of his work in Black Summer was documented
        by&nbsp;
        <a
          href="https://www.canberratimes.com.au/story/6543329/dr-ralph-is-saving-native-wildlife-one-burns-victim-at-a-time/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Canberra Times
        </a>
        ,&nbsp;
        <a
          href="https://www.facebook.com/abcinsydney/videos/tireless-veterinarian-helps-australias-wildlife-recover-from-fires/913049462444572/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ABC TV
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://www.facebook.com/WeAnimals/posts/pfbid029yiJRpQoN4kevTMTqVVVJYN16WFXncgydjgnpZQgxNJUDKhfwJtnqmy81ZCGhthVl?__tn__=H-R"
          target="_blank"
          rel="noopener noreferrer"
        >
          We Animals Media
        </a>
        .
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        Coming up from the South Coast, owner of{' '}
        <a
          href="https://www.instagram.com/wildtofree/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wild2Free Kangaroo Sanctuary
        </a>{' '}
        <b>Rae Harvey</b>, as seen in{' '}
        <a
          href="https://www.youtube.com/watch?v=YeIIc2w9Zog"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Bond
        </a>{' '}
        and{' '}
        <a
          href="https://www.youtube.com/watch?v=Hw86RI0AvmA"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Fire
        </a>
        . She is in the sad position of having personally known and cared for a
        number of Black Summer’s victims: many of the orphaned joeys she cared
        for were killed in the fires. (She nearly died herself too.) For three
        years, she has been unable to even speak their names. Now, for the first
        time, she will tell the story of the joeys she lost.
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable">
        Cultural burning practitioner and Southern NSW Regional Coordinator with{' '}
        <a
          href="https://www.firesticks.org.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firesticks Alliance
        </a>
        , Djiringanj-Yuin Custodian <b>Dan Morgan</b>. Dan practises{' '}
        <a
          href="https://www.acf.org.au/burning_question"
          target="_blank"
          rel="noopener noreferrer"
        >
          using Aboriginal knowledge to heal Country
        </a>
        . He has worked for 18 years with the NSW National Parks & Wildlife
        Service and is on the board of management for the Biamanga National
        Park, a sacred area home to{' '}
        <a
          href="https://www.abc.net.au/news/2022-04-17/koalas-saved-by-traditional-indigenous-burning/100988672"
          target="_blank"
          rel="noopener noreferrer"
        >
          the last surviving koalas on the NSW south coast
        </a>{' '}
        – which was partly destroyed by the fires of Black Summer.
        <i>
          “The animals that live on our sacred sites are our Ancestors, it’s our
          Cultural obligation to protect them. We have evolved with our Country
          over thousands of years, nourishing and protecting all living species.
          Our Country represents our people. So when the fires came, it was
          devastating to see the aftermath, and the feeling of helplessness was
          truly traumatising for our people, due to the denial of our Cultural
          right to manage Country as our Ancestors did for thousands of years
          prior to colonisation. Australia needs to make legislative changes
          that allow us to heal Country and our community through the fire
          knowledge and to stop incinerating ecosystems with destructive
          ‘hazard-reduction’ burns.”
        </i>
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        <b>Sonja Elwood</b>, wildlife rescuer and rehabilitator of 35 years, and
        a founding member of the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.nwc.org.au/"
        >
          NSW Wildlife Council
        </a>
        , which includes 26 wildlife groups representing over 4,000 volunteers
        in NSW. During Black Summer, Sonja worked with animal rescue
        organisations and local carers to establish emergency triage centres for
        wildlife on the South Coast. "I was working alongside wildlife carers
        who had lost their homes and whose animals had been lost in the fires.
        Others had been evacuated and didn't know if they had a house to go back
        to anymore. They would have had every right to just collapse in despair
        and they didn't. They said,{' '}
        <i style={{ marginRight: '2px' }}>
          what can I do; how can I help; put me on the roster; when do you want
          me tomorrow?
        </i>{' '}
        They showed up every day to help our burnt wildlife."
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        Head of Programs & Disaster Response at{' '}
        <a
          href="https://hsi.org.au/australian-wildlife-habitats/bushfires-and-disasters/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Humane Society International (HSI)
        </a>{' '}
        <b>Evan Quartermain</b>, who was one of the first responders on Kangaroo
        Island where nearly 40% of the island burnt at high severity:{' '}
        <i>
          “Those were some of the toughest scenes I’d ever witnessed as an
          animal rescuer: the bodies of charred animals as far as the eye can
          see. Every time we found an animal alive it felt like a miracle.”
        </i>{' '}
        As a result of this firsthand experience, HSI{' '}
        <a
          href="https://hsi.org.au/blog/safeguarding-australias-wildlife/"
          target="_blank"
          rel="noopener noreferrer"
        >
          commissioned a report
        </a>{' '}
        into the state of Australia’s disaster response for wildlife, which
        we’ll also hear about.
      </Text>
    ),
  },
  {
    body: (
      <Text className="text readable left">
        <a
          href="https://protect-au.mimecast.com/s/yFueCROND2uGv859Xc9Szx6?domain=sydney.edu.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Danielle Celermajer</strong>
        </a>
        , Deputy Director of the
        <a href="https://www.sydney.edu.au/sydney-environment-institute/">
          {' '}
          Sydney Environment Institute
        </a>{' '}
        and leader of the
        <a
          href="https://www.sydney.edu.au/arts/our-research/futurefix/multispecies-justice.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Multispecies Justice project
        </a>
        . In the midst of the fires, Danielle started to write about a new crime
        of our age &ndash;&nbsp;
        <a
          href="https://protect-au.mimecast.com/s/vy2rCXLW2mU4X3OvZFkr4UF?domain=abc.net.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>omnicide</i>
        </a>
        , the killing of all. Her book&nbsp;
        <a
          href="https://protect-au.mimecast.com/s/xHYZCWLVXkU65vD78CKl-z6?domain=penguin.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>Summertime</i>
        </a>
        &nbsp; narrates her firsthand experience of what catastrophic climate
        change feels like and means not only for humans, but for the animals
        whose worlds it is ruining. She will share the story of Jimmy and Katy,
        left to die as piglets on a factory farm floor, who'd been living safely
        on the land they shared with her and the rest of the multispecies
        community until Black Summer. Siblings and lifelong companions,
        tragically Katy was killed in the fires, with Jimmy left traumatised and
        griefstricken.
      </Text>
    ),
  },
];

const SuccessMessage = styled.div`
  .no-mgn {
    margin-bottom: 0;
  }
  .bold {
    font-weight: bold;
  }
  * {
    font-size: 20px;
  }
  svg {
    margin-left: 5px;
  }
  .ant-divider-vertical {
    margin: 0 10px !important;
    border-inline-start: 1px solid #cacaca;
  }
  text-align: center;
`;

const Wrapper = styled.div`
  .ant-row {
    justify-content: center;
  }
  .centered,
  .ant-form-item-explain-error {
    text-align: center;
  }
  .bsv-callout {
    text-align: center;
    .text {
      font-size: 20px;
    }
    .ant-divider {
      margin: 16px 0;
    }
  }
  .captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .bsv-rsvp-btn {
    display: inline-flex;
    align-items: center;
    margin: 20px 0;
    color: #111 !important;
    font-weight: 600;
    font-size: 25px;
    background-color: #dadada !important;
    padding: 25px 50px;
    &:hover {
      color: #111 !important;
      background-color: #fff !important;
    }
  }

  .call-out-btn {
    display: inline-flex;
    align-items: center;

    color: #111 !important;
    font-weight: 600;
    font-size: 25px !important;
    background-color: #dadada !important;
    padding: 7px 40px !important;
    margin-bottom: 10px;
    &:hover {
      color: #111 !important;
      background-color: #fff !important;
    }
  }

  .readable {
    font-family: libre-franklin, sans-serif;
    font-size: 18px !important;
  }
  .ant-list {
    text-align: left;
  }

  .ant-list-split .ant-list-item {
    border-block-end: none;
  }
  .ant-list-item-meta-avatar {
    display: none;
    @media (min-width: 400px) {
      display: block;
    }
  }
  .smaller-text {
    font-size: 16px !important;
  }
  .ant-alert-info {
    background-color: #222;
    border: 1px solid #555;
  }
  .ant-alert {
    img {
      max-width: 40px;
    }
    margin: 5px 0;
    padding: 16px 12px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const bsvSydneyTag = '296858';
const bsvOnlineTag = '296857';

function HomeForm({ onValidated }) {
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [rsvpFor, setRsvpFor] = useState(null);
  const [accessibilityDetails, setAccessibilityDetails] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [submitState, setSubmitState] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const recaptchaRef = React.createRef();

  function onChange(value) {
    setCaptcha(value);
    if (value) setShowCaptchaError(false);
  }

  const handleSubmit = (e) => {
    console.log(e);
    if (!captcha) {
      setShowCaptchaError(true);
      return;
    }
    // e.preventDefault();
    email &&
      firstName &&
      lastName &&
      rsvpFor &&
      email.indexOf('@') > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE3: rsvpFor,
        MERGE4: organisation,
        MERGE5: accessibilityDetails,
        tags: rsvpFor === 'bsv-in-person' ? bsvSydneyTag : bsvOnlineTag,
      });

    setTimeout(function () {
      setSubmitState(true);
    }, 1000);
  };

  const onFinishFailed = (errorInfo) => {
    captcha ? setShowCaptchaError(false) : setShowCaptchaError(true);
    console.log('Failed:', errorInfo);
  };

  return (
    <Wrapper>
      <Seo
        title="Black Summer Vigil | 2pm Sunday 2 April 2023, Sydney"
        description="three-year anniversary memorial service for the three billion animals who died in the fires."
      />
      <Container>
        <Row>
          <Col lg={15}>
            <Card
              className="bsv-callout"
              style={{
                marginBottom: 48,
                paddingTop: '24px',
                paddingBottom: '24px',
              }}
            >
              <Title
                level={2}
                className="centered"
                style={{ textTransform: 'uppercase' }}
              >
                Black Summer Vigil
              </Title>

              <Space direction="vertical">
                <Text className="text" italic>
                  three-year anniversary memorial service for the three billion
                  animals who died in the fires
                </Text>
                <Text className="text" strong>
                  2pm Sunday 2 April 2023 (AEST)
                </Text>
                <Text className="text" strong>
                  Camperdown Memorial Rest Park (Sydney) or{' '}
                  <a href="#live-stream">Attend Online</a>
                </Text>
                <Alert
                  showIcon
                  icon={<img src={stars} alt="wet weather" />}
                  message={
                    <Text className="text" strong>
                      *IF IT'S OVERCAST: Vigil will be held next door to the
                      park in Newtown Main Hall, 189 Church Street (entrance
                      opposite Mechanic St)
                    </Text>
                  }
                  type="info"
                />
                <Title
                  id="live-stream"
                  level={2}
                  className="centered"
                  style={{ textTransform: 'uppercase', marginTop: 12 }}
                >
                  Black Summer Vigil Live Stream:
                </Title>
                <div class="video-container" style={{ marginBottom: 10 }}>
                  <iframe
                    width="100%"
                    height="515"
                    src="https://www.youtube.com/embed/cr1VmQ0CT_Q"
                    title="Black Summer Vigil"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <Text className="text ">
                  You can also view the livestream&nbsp;
                  <a href="https://www.facebook.com/departmentofanimals.tv/">
                    on Facebook here
                  </a>
                  &nbsp;or&nbsp;
                  <a href="https://www.youtube.com/@departmentofanimals">
                    on YouTube here
                  </a>
                  .{' '}
                </Text>
                <Text className="text ">
                  Bringing together stories from first responders across
                  wildlife rescue, rural fire service, Aboriginal custodianship,
                  veterinary medicine, ecology, and more.
                </Text>

                <Button
                  href="#bsv-rsvp"
                  size="large"
                  className="centered bsv-rsvp-btn "
                  type="primary"
                >
                  RSVP
                </Button>

                <Text className="text readable smaller-text">
                  The Black Summer Vigil is brought to you by the{' '}
                  <a
                    href="https://departmentofanimals.tv/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Department of Animals
                  </a>
                  ,{' '}
                  <a
                    href="https://animalsaustralia.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Animals Australia
                  </a>
                  , the{' '}
                  <a
                    href="https://www.nwc.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NSW Wildlife Council
                  </a>
                  ,{' '}
                  <a
                    href="https://www.worldanimalprotection.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    World Animal Protection
                  </a>
                  ,{' '}
                  <a
                    href="https://hsi.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Humane Society International
                  </a>{' '}
                  and&nbsp;
                  <a
                    href="https://www.defendthewild.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Defend the Wild
                  </a>
                  , with support from{' '}
                  <a
                    href="https://www.wires.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WIRES
                  </a>
                  ,{' '}
                  <a
                    href="https://www.firesticks.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Firesticks Alliance
                  </a>
                  ,{' '}
                  <a
                    href="https://www.nature.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nature Conservation Council of NSW
                  </a>
                  ,{' '}
                  <a
                    href="https://wild2free.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Wild2Free Kangaroo Rescue
                  </a>
                  ,{' '}
                  <a
                    href="https://www.four-paws.org.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Four Paws
                  </a>
                  ,{' '}
                  <a
                    href="https://friendsofthekoala.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Friends of the Koala
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.kangaroosalive.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kangaroos Alive
                  </a>
                  .
                </Text>
                <Title
                  level={3}
                  style={{
                    textTransform: 'uppercase',
                    marginTop: '.8em',
                    marginBottom: '.6em',
                  }}
                >
                  FEATURING:
                </Title>
                <Text className="text readable">
                  <b>
                    PERFORMANCE &amp; CEREMONY BY{' '}
                    <a
                      href="https://www.instagram.com/jannawidanceclan/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JANNAWI DANCE CLAN
                    </a>
                  </b>
                </Text>
                <Text className="text readable">
                  sharing a Dharug cultural perspective to honour the Ancestors
                  and bring the spirit of the animals into the gathering
                </Text>
                <div style={{ marginTop: 5 }} />
                <Text className="text readable">
                  <b>EULOGY BY ALEXIS WRIGHT</b>
                </Text>
                <Text className="text readable">
                  Eulogy for three billion animals killed on their homelands by
                  multi-award-winning Waanyi writer and land rights activist
                  Alexis Wright. The only person to have won both the Miles
                  Franklin Award and the Stella Prize, and many other awards
                  besides. Known for her works including&nbsp;
                  <i>Carpentaria</i>,&nbsp;<i>Tracker</i>,&nbsp;
                  <i>The Swan Book</i>, with her sixth book&nbsp;
                  <i>Praiseworthy&nbsp;</i>coming out in April.
                </Text>
                <Title
                  level={3}
                  style={{
                    textTransform: 'uppercase',
                    marginTop: '.8em',
                    marginBottom: '.2em',
                  }}
                >
                  SPEAKERS:
                </Title>
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  size="small"
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar className="bullet" src={stars} />}
                        description={item.body}
                      />
                    </List.Item>
                  )}
                />
              </Space>
            </Card>
          </Col>
        </Row>
        <Title id="bsv-rsvp" level={2} className="centered">
          Black Summer Vigil RSVP:
        </Title>
        {!submitState ? (
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="attendance-type"
              rules={[
                {
                  required: true,
                  message: 'Please input how you plan to attend',
                },
              ]}
            >
              <Select
                size="large"
                placeholder="attendance type"
                onChange={(e) => setRsvpFor(e)}
              >
                <Select.Option value="bsv-in-person">
                  in person (Sydney)
                </Select.Option>
                <Select.Option value="bsv-online">attend online</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email' },
                { type: 'email', message: 'Please input a valid email' },
              ]}
            >
              <Input
                size="large"
                type="email"
                placeholder="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: 'Please input your first name' },
              ]}
            >
              <Input
                size="large"
                placeholder="first name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: 'Please input your last name' },
              ]}
            >
              <Input
                size="large"
                placeholder="last name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>

            <Form.Item name="organisation">
              <Input
                size="large"
                placeholder="organisation (optional)"
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="accessibility">
              <Input
                size="large"
                placeholder="any accessibility needs? (optional)"
                onChange={(e) => setAccessibilityDetails(e.target.value)}
              />
            </Form.Item>
            <ReCAPTCHA
              ref={recaptchaRef}
              className="captcha"
              sitekey="6LctqIEkAAAAAKgRXGXOlc5PvjKOnOp30pIZiBmk"
              onChange={onChange}
            />
            {showCaptchaError && (
              <div
                style={{
                  marginTop: '-20px',
                  textAlign: 'center',
                }}
              >
                <div
                  className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-40djjc"
                  role="alert"
                >
                  <div
                    className="ant-form-item-explain-error"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Please confirm humanity
                  </div>
                </div>
                <div style={{ width: '0px', height: '24px' }}></div>
              </div>
            )}

            <Form.Item className="centered" {...tailLayout}>
              <Button className="sub-btn" htmlType="submit" size="large">
                {submitState ? (
                  <>
                    Submitted <CheckOutlined />
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <SuccessMessage>
            <Row>
              <Col lg={15}>
                <Card
                  size="small"
                  className="bsv-callout"
                  style={{
                    marginBottom: 48,
                    marginTop: 24,
                    paddingTop: '24px',
                    paddingBottom: '24px',
                  }}
                >
                  <Title level={2} className="centered">
                    Thank you for RSVPing
                  </Title>
                  <Space direction="vertical">
                    <FacebookShareButton
                      url={'https://www.facebook.com/events/1202198153752662'}
                      hashtag="#blacksummervigil"
                    >
                      <Button
                        className=" call-out-btn"
                        icon={<FacebookFilled />}
                      >
                        Share Facebook event
                      </Button>
                    </FacebookShareButton>
                    <Title
                      level={3}
                      className="centered"
                      style={{ marginBottom: 0 }}
                    >
                      Follow on:
                    </Title>
                    <Paragraph>
                      <a
                        href="https://www.instagram.com/departmentofanimals.tv/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                      <Divider type="vertical" />
                      <a
                        href="https://www.youtube.com/@departmentofanimals"
                        target="_blank"
                        rel="noreferrer"
                      >
                        YouTube
                      </a>
                      <Divider type="vertical" plain={false} />
                      <a
                        href="https://www.facebook.com/departmentofanimals.tv "
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                      <Divider type="vertical" />
                      <a
                        href="https://twitter.com/deptofanimals"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                      <Divider type="vertical" />
                      <a
                        href="https://www.tiktok.com/@departmentofanimals.tv"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TikTok
                      </a>
                    </Paragraph>
                  </Space>
                </Card>
              </Col>
            </Row>
          </SuccessMessage>
        )}
      </Container>
    </Wrapper>
  );
}

export default HomeForm;
