import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 10px 20px;
  @media only screen and (min-width: 600px) {
    padding: 10px 30px;
  }
`;

function Container({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

export default Container;
