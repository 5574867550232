import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import BlackSummerVigil from './pages/BlackSummerVigil';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import HeaderComp from './Components/common/Header';
import Footer from './Components/common/Footer';
import ScrollToTop from './Components/small/ScrollToTop';
import { ConfigProvider } from 'antd';
import DarkTheme from './Components/common/DarkTheme';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <DarkTheme>
        <ScrollToTop />
        <div>
          <HeaderComp />
          <Home />
        </div>
      </DarkTheme>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/blacksummervigil',
    element: (
      <DarkTheme>
        <ScrollToTop />
        <div>
          <HeaderComp />
          <BlackSummerVigil />
        </div>
        <Footer />
      </DarkTheme>
    ),
  },
  {
    path: '/contact',
    element: (
      <DarkTheme>
        <ScrollToTop />
        <div>
          <HeaderComp />
          <Contact />
        </div>
        <Footer />
      </DarkTheme>
    ),
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
          fontFamily: 'typewriter, courier, monospace',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
