import { Layout } from 'antd';
import Container from './Container';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logoWhite from '../../assets/stars.png';
import bsvWhite from '../../assets/bsv-white.png';
import contactWhite from '../../assets/contact-white.png';

const { Header } = Layout;

const Wrapper = styled.div`
  * {
    text-align: center;
  }
  .nav-link {
    margin-right: 16px;
    display: inline-flex;
  }
  .active {
    border-bottom: 2px solid #fff;
  }
  nav {
    margin-top: 5px;
    img {
      max-height: 72px;
    }
  }
`;

function HeaderComp({ dark }) {
  let activeClassName = 'active';
  return (
    <Wrapper>
      <Container>
        <Header style={{ padding: 0 }}>
          <nav>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? activeClassName + ' nav-link' : 'nav-link'
              }
            >
              <img src={logoWhite} alt="home" />
            </NavLink>

            <NavLink
              to="/blacksummervigil"
              className={({ isActive }) =>
                isActive ? activeClassName + ' nav-link' : 'nav-link'
              }
            >
              <img src={bsvWhite} alt="home" />
            </NavLink>

            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? activeClassName + ' nav-link' : 'nav-link'
              }
            >
              <img src={contactWhite} alt="home" />
            </NavLink>
          </nav>
        </Header>
      </Container>
    </Wrapper>
  );
}

export default HeaderComp;
