import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import doaLogo from '../../assets/doa-logo.svg';
import { NavLink } from 'react-router-dom';

const { Title } = Typography;
const Wrapper = styled.div`
  > div {
    filter: invert(0.2);
  }
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  * {
    text-align: center;
  }
  .logo {
    margin: 0px 0 10px 0;
    max-height: 140px;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Container>
        <NavLink to="/">
          <img className="logo" src={doaLogo} alt="home" />
        </NavLink>
        <Title level={5}>
          Stories from the guerrilla war against the sixth mass extinction
        </Title>
      </Container>
    </Wrapper>
  );
}

export default Footer;
