import React from 'react';
import { ConfigProvider, theme } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
  background: #111;
  button: {
    box-shadow: none !important;
  }
  input,
  button,
  .ant-select-selector,
  .ant-select-item {
    padding: 7px 11px !important;
    height: unset !important;
    font-size: 20px !important;
  }
`;

function DarkTheme({ children }) {
  return (
    <Wrapper>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorError: '#fafafa',
            colorPrimaryHover: '#fafafa !important',
            colorLink: 'rgba(255, 255, 255, 0.85)',
            colorLinkActive: '#fff',
            colorLinkHover: '#fff',
            colorTextPlaceholder: '#999',
            colorPrimary: '#222',
          },
          components: {
            Button: {
              colorPrimaryHover: '#fafafa !important',
              colorPrimaryActive: '#fafafa !important',
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </Wrapper>
  );
}

export default DarkTheme;
