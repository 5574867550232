import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import HomeForm from '../Components/big/HomeForm';
import styled from 'styled-components';
import Container from '../Components/common/Container';
import doaLogo from '../assets/doa-logo.svg';
import { Typography } from 'antd';
const { Title } = Typography;

const Wrapper = styled.div`
  text-align: center;
  .logo {
    margin: 0 auto;
    max-height: 60vh;
  }
`;

const url =
  'https://departmentofanimals.us21.list-manage.com/subscribe/post?u=8ed2c39de3b9c27e42325b027&id=afa9b66685';

function Home() {
  return (
    <Wrapper>
      <Container>
        <img className="logo" src={doaLogo} alt="home" />
        <Title level={2}>
          Stories from the guerrilla war against the sixth mass extinction
        </Title>
        <br />

        <MailchimpSubscribe
          url={url}
          render={({ subscribe }) => (
            <HomeForm onValidated={(formData) => subscribe(formData)} />
          )}
        />
      </Container>
    </Wrapper>
  );
}

export default Home;
