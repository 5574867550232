import React from 'react';
import { Helmet } from 'react-helmet';
import defaultThumb from '../../assets/thumb.jpg';

function Seo({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
  image,
}) {
  const metaDescription =
    description ||
    'Stories from the guerrilla war against the sixth mass extinction';
  const _image = image || defaultThumb;
  const _title = title || 'Department Of Animals';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: _title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Department of Animals',
        },
        {
          name: `twitter:title`,
          content: _title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `thumbnail`,
          content: _image,
        },
        {
          prefix: 'og: http://ogp.me/ns#',
          property: 'og:image',
          name: `og:image`,
          content: _image,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

export default Seo;
