import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Card,
  Space,
  Divider,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Seo from '../common/Seo';

const { Title, Paragraph } = Typography;

const layout = {
  wrapperCol: { lg: 14, xl: 10 },
};
const tailLayout = {
  wrapperCol: { lg: 14, xl: 10 },
};
const Wrapper = styled.div`
  text-align: center;
  .ant-row {
    justify-content: center;
  }
  .captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const SuccessMessage = styled.div`
  .no-mgn {
    margin-bottom: 0;
  }
  .bold {
    font-weight: bold;
  }
  * {
    font-size: 20px;
  }
  svg {
    margin-left: 5px;
  }
  .ant-divider-vertical {
    margin: 0 10px !important;
    border-inline-start: 1px solid #cacaca;
  }
  text-align: center;

  .call-out-btn {
    display: inline-flex;
    align-items: center;

    color: #111 !important;
    font-weight: 600;
    font-size: 25px !important;
    background-color: #dadada !important;
    padding: 7px 40px !important;
    margin-bottom: 10px;
    &:hover {
      color: #111 !important;
      background-color: #fff !important;
    }
  }
`;

const homeFormTag = '63040';

function HomeForm({ onValidated }) {
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState('');
  const [subscribeState, setSubscribeState] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  function onChange(value) {
    setCaptcha(value);
    if (value) setShowCaptchaError(false);
  }

  const handleSubmit = (e) => {
    console.log(e);
    // e.preventDefault();
    if (!captcha) {
      setShowCaptchaError(true);
      return;
    }

    email &&
      firstName &&
      lastName &&
      email.indexOf('@') > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE6: address,
        tags: homeFormTag,
      });

    setTimeout(function () {
      setSubscribeState(true);
    }, 1000);
  };

  const onFinishFailed = (errorInfo) => {
    captcha ? setShowCaptchaError(false) : setShowCaptchaError(true);
    console.log('Failed:', errorInfo);
  };

  return (
    <Wrapper>
      <Seo />
      <Title level={3}>Receive transmissions:</Title>
      {!subscribeState ? (
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email' },
              { type: 'email', message: 'Please input a valid email' },
            ]}
          >
            <Input
              size="large"
              type="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: 'Please input your first name' },
            ]}
          >
            <Input
              size="large"
              placeholder="first name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name' }]}
          >
            <Input
              size="large"
              placeholder="last name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="postcode">
            <Input
              size="large"
              placeholder="postal address (if you like)"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          <ReCAPTCHA
            className="captcha"
            sitekey="6LctqIEkAAAAAKgRXGXOlc5PvjKOnOp30pIZiBmk"
            onChange={onChange}
          />
          {showCaptchaError && (
            <div
              style={{
                marginTop: '-20px',
                textAlign: 'center',
              }}
            >
              <div
                className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-40djjc"
                role="alert"
              >
                <div
                  className="ant-form-item-explain-error"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Please confirm humanity
                </div>
              </div>
              <div style={{ width: '0px', height: '24px' }}></div>
            </div>
          )}

          <Form.Item {...tailLayout}>
            <Button htmlType="submit" size="large">
              {subscribeState ? (
                <>
                  Submitted <CheckOutlined />
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <SuccessMessage>
          <Row>
            <Col lg={15}>
              <Card
                size="small"
                className="bsv-callout"
                style={{
                  marginBottom: 48,
                  marginTop: 24,
                  paddingTop: '24px',
                  paddingBottom: '24px',
                }}
              >
                <Title
                  level={2}
                  className="centered"
                  style={{ marginBottom: '20px' }}
                >
                  Thank you for signing up
                </Title>
                <Space direction="vertical">
                  <Title
                    level={3}
                    className="centered"
                    style={{ marginBottom: 0 }}
                  >
                    Follow on:
                  </Title>
                  <Paragraph>
                    <a
                      href="https://www.instagram.com/departmentofanimals.tv/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <Divider type="vertical" />
                    <a
                      href="https://www.youtube.com/@departmentofanimals"
                      target="_blank"
                      rel="noreferrer"
                    >
                      YouTube
                    </a>
                    <Divider type="vertical" plain={false} />
                    <a
                      href="https://www.facebook.com/departmentofanimals.tv "
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    <Divider type="vertical" />
                    <a
                      href="https://twitter.com/deptofanimals"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                    <Divider type="vertical" />
                    <a
                      href="https://www.tiktok.com/@departmentofanimals.tv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TikTok
                    </a>
                  </Paragraph>
                </Space>
              </Card>
            </Col>
          </Row>
        </SuccessMessage>
      )}
    </Wrapper>
  );
}

export default HomeForm;
