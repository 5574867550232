import React from 'react';
import Container from '../Components/common/Container';
import { Typography, Divider } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  .no-mgn {
    margin-bottom: 0;
  }
  .bold {
    font-weight: bold;
  }
  * {
    font-size: 20px;
  }
  svg {
    margin-left: 5px;
  }
  .ant-divider-vertical {
    margin: 0 10px;
    border-inline-start: 1px solid #cacaca;
  }
  text-align: center;
`;

const { Paragraph } = Typography;

function Contact() {
  return (
    <Wrapper>
      <Container>
        <Paragraph className="no-mgn bold">General enquiries</Paragraph>
        <Paragraph>hello@departmentofanimals.tv</Paragraph>
        <Paragraph className="no-mgn bold">Media</Paragraph>
        <Paragraph>media@departmentofanimals.tv</Paragraph>
        <Paragraph>
          <a
            href="https://www.instagram.com/departmentofanimals.tv/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <Divider type="vertical" />
          <a
            href="https://www.youtube.com/@departmentofanimals"
            target="_blank"
            rel="noreferrer"
          >
            YouTube
          </a>
          <Divider type="vertical" plain={false} />
          <a
            href="https://www.facebook.com/departmentofanimals.tv "
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <Divider type="vertical" />
          <a
            href="https://twitter.com/deptofanimals"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          <Divider type="vertical" />
          <a
            href="https://www.tiktok.com/@departmentofanimals.tv"
            target="_blank"
            rel="noreferrer"
          >
            TikTok
          </a>
        </Paragraph>
      </Container>
    </Wrapper>
  );
}

//safe way to target="_blank" in html no
export default Contact;
